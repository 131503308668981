import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import AlbumDisplay from "./AlbumDisplay"
import ImageDropCrop from "./ImageDropCrop"
import axios from 'axios';
import AlbumChoice from './AlbumChoice';
import oktoImg from './Oktoberfest.jpg';
import commiImg from './commi.png';
import giImg from './giphoto.jpg';
import ReactGA from 'react-ga';
import { Redirect } from 'react-router-dom';
import { keyBy,isEmpty } from 'lodash';

ReactGA.initialize('UA-128167255-1');

function accentAndCaseRemover(initialString){
  if (initialString){
    const replacementDict = {
      'a': ['â','à','ä','Â','À','Ä'],
      'e': ['é','ê','ë','è','É','È','Ê','Ë'],
      'c': ['ç','Ç'],
      'i': ['î','ï','ì','Î','Ï','Ì'],
      'o': ['ô','ö','ò','Ô','Ö','Ò'],
      'u': ['û','ü','ù','Û','Ü','Ù']
    }
    for (var key in replacementDict){
      replacementDict[key].forEach(
        specialLetter => {
          //console.log(specialLetter);
          initialString = initialString.replace(new RegExp(specialLetter, 'g'),key);
        }
      );
    }
  
    initialString = initialString.toLowerCase();
  }
  return initialString
}

const styles = {
  tabs: {
    background: '#fff',
  },
  slide: {
    padding: "0px",
    minHeight: "1000px",
    color: '#fff',
    backgroundColor: '#282c34',
    WebkitOverflowScrolling: 'touch',
  },
  slide1: {
    //backgroundColor: '#FEA900',
    backgroundColor: '#282c34',
    paddingTop: "30px",
  },
  slide2: {
    backgroundColor: '#B3DC4A',
    //backgroundColor: '#282c34'
  },
  slide3: {
    backgroundColor: '#FFFFFF',
  },
};

const pageRoutes = [
    { index: 0, type: 'search', title: "Main page" },
    { index: 1, type: 'choice', title: 'Page choice' },
    { index: 2, type: 'album', title: 'Results' },
  ];
  
  const tabsPageMap = keyBy(pageRoutes, 'type');
  const indexToRouteMap = keyBy(pageRoutes, 'index');

const choices = {
  "grenouillesherb":{ 
    value:"grenouillesherb",
    name:"Petite Grenouille",
    id: "grenouillesherbrooke",
    imgUrl: "",
    message: "La P’tite Grenouille, c’est un concept de boîte à chansons sans prétention, là où il fait bon chanter à tue-tête des vieux hits « comme dans l’temps » ! "
    },
    "pixel":{
    value:"pixel",
    name:"Pixels photographie (Génie)",
    id: "pixelsphotographie",
    message: "Photographe de la promotion 60 de la faculté de génie de l'Université de Sherbrooke."
    },
    "genik":{
    value:"genik",
    name:"Photo Génik (Génie)",
    id: "PhotosGeniK",
    message: "Les photos des 5@8 en Génie de l'UdeS sont ici!!"
    },
    "giphoto":{
      value:"giphoto",
      name:"Gi Photo (Génie)",
      imgUrl: giImg,
      id: "gigi.photographie",
      message: "Imortalisation en photos de vos moments par Geneviève Leroux."
      },
    "commi":{
    value:"commi",
    name:"Commission des liqueurs",
    id: "CommissionLiqueursSherbrooke",
    imgUrl: commiImg,
    message: "Une autre façon... de boire les choses!"
    },
    "okto2018":{
      value:"okto2018",
      name:"Oktoberfest 2018",
      id: "oktoberfest.sherbrooke",
      imgUrl: oktoImg,
      message: "Le plus gros party universitaire thématique au Canada!"
    },
    "oktoberfestsherb":{
    value:"oktoberfestsherb",
    name:"Oktoberfest",
    id: "oktoberfest.sherbrooke",
    imgUrl: oktoImg,
    message: "Le plus gros party universitaire thématique au Canada!"
    },
    "antistress":{
    value:"antistress",
    name:"Les Anti-Stress de Mr Ménard (Génie)",
    id: "MonsieurMenard",
    message: "Photos \" Anti Stress \" d'émotions et de moments importants "
    },
    "regs":{
    value:"regs",
    name:"REGS (Admin)",
    id: "regs.sherbrooke",
    message: "Le REGS (Regroupement des étudiants de l'École de Gestion de Sherbrooke) est un organisme à but non-lucratif qui représente les intérêts des étudiants."
    },
    "droitcomite":{
    value:"droitcomite",
    name:"Comité droit 2018-19",
    id: "Comitepromo",
    message:"Le Comité Promo de la faculté de droit de l'Université de Sherbrooke a comme objectif de vous faire passer des soirées mémorables."
    },
    "vadmin":{
    value:"vadmin",
    name:"Photo Voltaic (Admin)",
    url: "https://voltaic.ca/",
    id: "VoltaicPhoto",
    message: "Capturer la lumière, transmettre l'énergie"
    },
    "vgenie":{
    value:"vgenie",
    name:"Photo Voltaic (Génie)",
    url: "https://voltaic.ca/",
    id: "VoltaicPhoto",
    message: "Capturer la lumière, transmettre l'énergie"
    },
    "vdroitNkinNflesh":{
    value:"vdroitNkinNflesh",
    name:"Photo Voltaic (Droit, Kin, FLESH)",
    url: "https://voltaic.ca/",
    id: "VoltaicPhoto",
    message: "Capturer la lumière, transmettre l'énergie"
    },
    "vfmss":{
    value:"vfmss",
    name:"Photo Voltaic (FMSS)",
    url: "https://voltaic.ca/",
    id: "VoltaicPhoto",
    message: "Capturer la lumière, transmettre l'énergie"
    },
    "vscienceNeduc":{
    value:"vscienceNeduc",
    name:"Photo Voltaic (Science & Éduc)",
    url: "https://voltaic.ca/",
    id: "VoltaicPhoto",
    message: "Capturer la lumière, transmettre l'énergie"
    },
    "vparty":{
    value:"vparty",
    name:"Photo Voltaic (FEUS, Rentrée)",
    url: "https://voltaic.ca/",
    id: "VoltaicPhoto",
    message: "Capturer la lumière, transmettre l'énergie"
    },
    "vautre":{
    value:"vautre",
    name:"Photo Voltaic (autre)",
    url: "https://voltaic.ca/",
    id: "VoltaicPhoto",
    message: "Capturer la lumière, transmettre l'énergie"
    },
    "vgeniecompet":{
      value:"vgeniecompet",
      name:"Photo Voltaic (JDG, CQI, CSGames)",
      url: "https://voltaic.ca/",
      id: "VoltaicPhoto",
      message: "Capturer la lumière, transmettre l'énergie"
    },
    "wrench":{
      value: "wrench",
      name: "Wrench & Hammer (Génie)",
      id: "WrenchPhotos",
      message: "Est-ce que tu goûtes le bacon? -Une fan de sauce Ranch"
    },
    "all":{
      value: "all",
      name: "All the albums",
      id: "sherlomeapp",
      message: "Here are all the results! It may take up to 3 minutes to process"
    },
}
/*
function downloadBase64File(base64Data, filename) {
  var element = document.createElement('a');
  element.setAttribute('href', base64Data);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}*/

function base64StringtoFile(base64String, filename) {
  var arr = base64String.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

function extractImageFileExtensionFromBase64(base64Data){
  return base64Data.substring("data:image/".length, base64Data.indexOf(";base64"))
}

export function image64toCanvasRef(canvasRef, image64, pixelCrop){
  const canvas = canvasRef // document.createElement('canvas');
  if (pixelCrop.height === 0 && pixelCrop.width === 0){
    const ctx = canvas.getContext('2d');
    const image = new Image()
    image.src = image64
    canvas.width = image.width;
    canvas.height = image.height;
    image.onload = function() {
      ctx.drawImage(image,
        0, 0, image.width, image.height)
      console.log(image.width, image.height)
    }
  }
  else{
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');
    const image = new Image()
    image.src = image64
    image.onload = function() {
        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height
        )
      }
  }
}

class MainView extends React.Component {
  constructor(){
    super();
    this.imagePreviewCanevasRef = React.createRef();
    this.state = {
      index: 0,
      imgSrc : null,
      crop:{
        x: 20,
        y: 20,
        width: 20,
        height: 15
      },
      data : [],
      imgData: [],
      loading: false,
      pagename: null,
      buttonSelected: undefined,
      pageInfo: {},
      selected: null,
      searchTerm: "",
      filteredChoice: choices,
      allSearch: false,
      customMessage: "",
      resultSort:true,
    }
  }

  handleChange = (event, value) => {
    const { history } = this.props;
    const { push } = history;
    push(`/${indexToRouteMap[value].type}`)
    window.scrollTo(0, 0)
    this.setState({
      index: value,
    });
  };

  handleChangeIndex = index => {
    const { history } = this.props;
    const { push } = history;
    push(`/${indexToRouteMap[index].type}`)
    window.scrollTo(0, 0)
    this.setState({
      index,
    });
  };

  handleOnCropChange = (crop)=>{
    //console.log(crop)
    this.setState({crop:crop});
    //console.log(this.state.crop);
  }

  handleDrop = (files, rejectedFiles)=>{
    //console.log("accpt", files)
    //console.log("Reject", rejectedFiles)
    if(files && files.length !== 0){
      //alert(JSON.stringify(files));
      const currentFile = files[0];
      //console.log(currentFile);
      const reader = new FileReader();
      reader.addEventListener("load", ()=>{
        //console.log(reader.result);
        this.setState({
          imgSrc: reader.result
        });
      }, false);
      reader.readAsDataURL(currentFile);
    }
    ReactGA.event({
      category: 'PutPhoto',
      action: 'Drop photo'
    });
  }

  handleOnCropComplete = (crop, pixelCrop) =>{
    console.log(crop, pixelCrop);
    const canvasRef = this.imagePreviewCanevasRef.current;
    console.log(canvasRef)
    const {imgSrc} = this.state;
    //console.log(imgSrc)
    image64toCanvasRef(canvasRef, imgSrc, pixelCrop);
  }

  handleClearToDefault = event =>{
    if (event) event.preventDefault()
    const { history } = this.props;
    const { push } = history;
    push(`/${indexToRouteMap[0].type}`)
    const canvas = this.imagePreviewCanevasRef.current
    if (canvas){
      const ctx = canvas.getContext('2d')
      ctx.clearRect(0,0,canvas.width,canvas.height)
    }
    this.setState({
      index:0,
      buttonSelected: undefined,
      imgSrc : null,
      crop:{
        x: 20,
        y: 20,
        width: 20,
        height: 15
      },
      data: [],
      imgData: [],
      pageInfo: {},
      selected: null,
      customMessage: ""
    })
  }

 

  sendImageDataToAll = (file, filename) =>{
    var resultList = {
        hasFace: false,
        matchs:[],
        speed:0,
    }
    var count = 0;

    this.setState({loading: true})
    if(count === 0){
        this.setState({index: 2, selected: "all", pageInfo:choices["all"]})
        window.scrollTo(0, 0)
    }
      
    function requestAndConcat(allKeys, index, length, that){
        that.setState({customMessage: "In progress: " +choices[allKeys[index]].name+"... "+(index+1)+"/"+length})
        const reqURL = "https://api.urbanrater.com:5001/?page="+allKeys[index]
        var formData = new FormData()
        formData.append("file", file, filename);
        //formData.append("page", "test");
        axios.post(reqURL , formData, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'accept': 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        }
        })
        .then((response) => {
            //const imgData = this.createImageFromData(response.data)
            
            if(response.data.matchs !== undefined)
                resultList.matchs = resultList.matchs.concat(response.data.matchs) //add the result to our list
                resultList.hasFace = resultList.hasFace || response.data.hasFace
                that.setState({data:resultList, loading:false})
            console.log(index,"/",length-1);
            console.log(resultList.matchs);

            const limitTolerance = 0.44;
            const showableResult = resultList.matchs.filter(elem => {
                return elem['distance'] < limitTolerance
            })
            console.log("show", showableResult);

            var sleepTime = 1000;
            if (showableResult.length < 5) sleepTime = 50 //no sleep if no significant result

            if (index+1 !== length  && response.data.hasFace){
                setTimeout(() => {
                    if (that.state.allSearch)
                        requestAndConcat(allKeys, index+1, lengthAll, that)
                    else
                        that.setState({loading:false, customMessage:""})
                }, sleepTime);
            }
            else{
                that.setState({loading:false, customMessage:""})
            }
        }).catch((error) => {
            //handle error
            that.setState({loading:false})
            console.log("Error",error)
        });
        
    }

    const allKeys = Object.keys(choices).filter(key=>key !== "all");
    const lengthAll = allKeys.length;

    requestAndConcat(allKeys, 0, lengthAll, this);

    //if multi-processing
    /*for (var i = 0; i < lengthAll; i++) { 
        requestAndConcat(allKeys, i, lengthAll, this)
    }*/ 
    

    
  }

  sendImageData = (file, filename, pagename=null) =>{
    //const reqURL = "http://192.168.5.128:5001/"
    if (pagename == null){
      pagename = this.state.pagename;
    }
    const reqURL = "https://api.urbanrater.com:5001/?page="+pagename
    var formData = new FormData()
    formData.append("file", file, filename);
    //formData.append("page", "test");
    this.setState({loading: true})
    axios.post(reqURL , formData, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'accept': 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      }
    })
    .then((response) => {
      //const imgData = this.createImageFromData(response.data)
      console.log(response);
      this.setState({data:response.data, loading:false})
    }).catch((error) => {
      //handle error
      this.setState({loading:false})
      console.log("Error",error)
    });
    
    // download
    //downloadBase64File(imageData64, myFileName);
  }
  backToPage1 = () =>{
    const { history } = this.props;
    const { push } = history;
    push(`/${indexToRouteMap[0].type}`)
    window.scrollTo(0, 0)
    this.setState({index:0, allSearch:false, customMessage:""})
    ReactGA.event({
      category: 'Back',
      action: 'Back to image cut'
    });
  }
  backToPage2 = () =>{
    const { history } = this.props;
    const { push } = history;
    push(`/${indexToRouteMap[1].type}`)
    window.scrollTo(0, 0)
    this.setState({imgData: []})
    this.setState({index:1, allSearch:false, customMessage:""})
    ReactGA.event({
      category: 'Back',
      action: 'Back to album selection'
    });
  }
  /*reateImageFromData = data =>{
    if (data){
      const matchData = data['matchs'];
      //console.log(data)
      //console.log(matchData)
      matchData.sort((dataDicta, dataDictb) =>{
        return dataDicta['distance'] - dataDictb['distance']
      });
      console.log(matchData)
      return matchData.map(dataDict =>{
        const imgNameSplit = dataDict['imageLocation'].split("/")
        const imgName = imgNameSplit[imgNameSplit.length-1].split(".")[0]
        
        return <div>
          <a href={dataDict['imgUrl']} id={imgName} target="_blank"><img style={{maxWidth:"100%", maxHeight:"80%"}} src={dataDict['imageLocation']} alt="resultimg"/></a>
          <br/>
          </div>
      });
    }
    else return 
  }*/

  handleButtonClick = (event) =>{
    event.preventDefault();
    
    const { file, filename } = this.cropImage()
    this.sendImageData(file, filename)
    /*base64Img.requestBase64("", 
    (err, res, body)=>{
      if (res){
        console.log(res);
      }
    })*/
  }

  cropImage = ()=>{
    const canvasRef = this.imagePreviewCanevasRef.current;
    const {imgSrc} = this.state;
    const fileExtension = extractImageFileExtensionFromBase64(imgSrc);
    const imageData64 = canvasRef.toDataURL('image/' + fileExtension)
    const myFileName = "preview." + fileExtension;

    // file to be uploaded
    const myNewCroppedFile = base64StringtoFile(imageData64, myFileName);
    console.log(myNewCroppedFile)
    return {file: myNewCroppedFile, filename:myFileName}
  }

  onRadioButtonChange = (value, event)=>{
    //console.log(value)
    this.setState({buttonSelected: value, pagename: value})
    ReactGA.pageview("/Album");
    ReactGA.event({
      category: 'Search',
      action: 'Search in album '+value,
      label: value
    });
    if (this.imagePreviewCanevasRef.current){
      const { history } = this.props;
      const { push } = history;
      push(`/${indexToRouteMap[2].type}`)
      const { file, filename } = this.cropImage()
      
      if(value === "all"){
        this.setState({allSearch:true, resultSort:false});
        this.sendImageDataToAll(file, filename);
      }
      else{
        this.setState({index: 2, selected: value, pageInfo:choices[value], resultSort:true})
        this.sendImageData(file, filename, value)
        window.scrollTo(0, 0)
      }
    }
  }

  onSearchTermChange = (event) => {
    const value = event.target.value;
    const filteredDict = this.filteredChoice(value);
    this.setState({searchTerm: value, filteredChoice: filteredDict});
    ReactGA.event({
      category: 'Search',
      action: 'search with term' + value
    });
    //console.log(value, filteredDict);
  }

  filteredChoice = (term) => {
    var filteredDict = Object.keys(choices).reduce(function (filtered, key) {
      if (accentAndCaseRemover(choices[key].name).toLowerCase().includes(accentAndCaseRemover(term).toLowerCase())) filtered[key] = choices[key];
      return filtered;
    },{});
    return filteredDict;
  }

  componentDidMount(){
    window.scrollTo(0, 0);
    ReactGA.pageview("/");
  }
  render() {
    const { match } = this.props;
    const { page } = match.params;
    //console.log("page", page)
    const tabsRoute = tabsPageMap[page];
    //console.log("TABroute", tabsRoute, this.state.pageInfo)

    if (!tabsRoute) {
        return <Redirect to="/search" />;
    }
    if (this.state.allSearch === true && tabsRoute.index < 2){
        console.log(tabsRoute.index, this.state.allSearch)
        this.setState({allSearch:false, customMessage: ""});
    }
    if (tabsRoute.index === 2 && isEmpty(this.state.pageInfo)) { // if in album and no page choice
        return <Redirect to="/search" />;
    }

    const { index, title } = tabsRoute;
    
    const {imgSrc, crop, filteredChoice} = this.state;
    const acceptedFileTypes = "image/x-png, image/png, image/jpg, image/jpg, image/jpeg";
    return (
      <div>
        {/*<Tabs value={index} fullWidth onChange={this.handleChange} style={styles.tabs}>
          <Tab label="Search" />
          <Tab label="Album choice" />
          <Tab label="Results" />
        </Tabs>*/}
        <SwipeableViews animateHeight index={index} onChangeIndex={this.handleChangeIndex} disabled={true}>
          <div style={Object.assign({}, styles.slide, styles.slide1)}>
           <ImageDropCrop next={this.handleChangeIndex} 
            acceptedFileTypes={acceptedFileTypes} 
            handleDrop={this.handleDrop} 
            imgSrc={imgSrc}
            handleOnCropChange = {this.handleOnCropChange}
            imagePreviewCanevasRef = {this.imagePreviewCanevasRef}
            handleClearToDefault = {this.handleClearToDefault}
            handleOnCropComplete = {this.handleOnCropComplete}
            crop={crop} 
            onComplete={this.handleOnCropComplete}
            />
          </div>
          <div style={Object.assign({}, styles.slide, styles.slide2)}>
            <AlbumChoice choices={filteredChoice} onRadioButtonChange={this.onRadioButtonChange} onSearchTermChange={this.onSearchTermChange} searchTerm={this.state.searchTerm} back={this.backToPage1} selected={this.state.selected}/>
          </div>
          <div style={Object.assign({}, styles.slide, styles.slide3)}>
            <AlbumDisplay data={this.state.data} resultSort={this.state.resultSort} loading={this.state.loading} pageInfo={this.state.pageInfo} back={this.backToPage2} newSearch={this.handleClearToDefault} customMessage={this.state.customMessage}/>
          </div>
        </SwipeableViews>
      </div>
    );
  }
}

export default MainView;