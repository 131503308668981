import React from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom";
import MainView from './MainView';

class App extends React.Component {
  render() {
    return(
      <Router>
          <Switch>
            <Route exact path="/:page" component={MainView} />

            <Redirect to="/search" />
          </Switch>
      </Router>
    );
  }
}

export default App;