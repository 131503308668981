import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import backButton from './backButton.png';
import InputBase from '@material-ui/core/InputBase';
import searchIcon from './search.png';
import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
    //backgroundColor: "#282c34"
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  avatar: {
    margin: theme.spacing.unit,
    width: '50px',
    height: '50px'
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 5}px 0`,
  },
  card: {
    height: '100%',
    display: 'flex',
  },
  cardMedia: {
    paddingTop: '66.66%', // 3:2
  },
  cardContent: {
    flexGrow: 1,
    paddingBottom: "0px"
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 6,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    paddingRight: '24px', //to center since the back button is 24px
    marginRight: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 2,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      //marginLeft: theme.spacing.unit * 3,
      marginRight: 'auto',
      marginLeft: 'auto',
      width: 'auto'
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 6,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 6,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 300,
    },
  },
});

function createPictureLinkFromId(id){
  return "https://graph.facebook.com/"+id+"/picture?type=square&width=100&height=100"
}
function getOpacityIfSelected(selected, choice){
  if(choice === selected) return "#8794d6"
  return "#FFFFFF"
}
function Album(props) {
  const { classes, choices, onRadioButtonChange, back, selected, onSearchTermChange, searchTerm } = props;
  function onButtonClick(value) {onRadioButtonChange(value)}; 
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" className={classes.appBar}>
          <Toolbar>
              <Button onClick={back} variant="outlined" color="primary" aria-label="Add" className={classes.button}>
                  <img style={{width:"24px"}} alt="Back" src={backButton}/>
              </Button>
              <div className={classes.search}>
              <div className={classes.searchIcon}>
                <img style={{width:"24px"}} alt="Back" src={searchIcon}/>
              </div>
              <InputBase
                placeholder="Try 'genie'"
                value={searchTerm}
                onChange={onSearchTermChange}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
          </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <div className={classes.heroUnit}>
          <div className={classes.heroContent}>
            <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
              Choose the page
            </Typography>
          </div>
        </div>
        <div className={classNames(classes.layout, classes.cardGrid)}>
          {/* End hero unit */}
          <Typography style={{marginBottom:"30px"}} component="h4" variant="h5" align="center" color="textPrimary" gutterBottom>
            Sherbrooke
          </Typography>
          <Grid container spacing={24}>
            {Object.values(choices).map(choice => (
              <Grid item key={choice.name} xs={12} sm={6} md={6} lg={4}>
                <Card className={classes.card}>
                    <Button style={{width:"100%", justifyContent:"left", backgroundColor:getOpacityIfSelected(selected,choice.value)}} onClick={()=>onButtonClick(choice.value)} className={classes.card} size="large" color="primary">
                    <Avatar className={classes.avatar}>
                      <img alt={choice.name} style={{ maxHeight: "50px"}} src={choice.imgUrl ? choice.imgUrl : createPictureLinkFromId(choice.id)}/>
                    </Avatar>
                    <br/>
                      <p style={{textAlign:"left"}}>{choice.name}</p>
                    </Button>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      </main>
      {/* Footer 
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Footer
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          Something here to give the footer a purpose!
        </Typography>
      </footer>
       End footer */}
    </React.Fragment>
  );
}

Album.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Album);