import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import logo from './logo2.svg'
import Dropzone from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import ReactGA from 'react-ga';

import 'react-image-crop/dist/ReactCrop.css';

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(700 + theme.spacing.unit * 3 * 2)]: {
      width: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.main,
    width: '80px',
    height: '80px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit * 3,
    alignItems: 'center',
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  dropZone:{
    margin: "auto",
    position: "relative",
    width: "200px",
    height: "150px",
    borderWidth: "2px",
    borderColor: "rgb(102, 102, 102)",
    borderStyle: "dashed",
    borderRadius: "5px",
    textAlign: "center",
    color: "black",
    verticalAlign: "bottom",
    marginBottom: "20px"
  },
  imgDiv: {
    //width: "auto",
    margin: "auto",
    display: "table",
    textAlign: "center",
  },
  cropZone:{
    margin: "auto",
  }
});
const dropZoneActive = {
    margin: "auto",
    position: "relative",
    width: "200px",
    height: "150px",
    borderWidth: "2px",
    borderColor: "rgb(0,128,0)",
    borderStyle: "solid",
    borderRadius: "5px",
    backgroundColor: "rgb(238,238,238)",
    textAlign: "center",
    color: "black",
    verticalAlign: "bottom",
    marginBottom: "20px"
  }


function ImageDropCrop(props) {
  const { classes, next, acceptedFileTypes, handleDrop, imgSrc, handleOnCropChange, imagePreviewCanevasRef, handleClearToDefault, crop, handleImageLoaded, handleOnCropComplete } = props;
  function nextSlide() {
    next(1);
    ReactGA.event({
      category: 'Next',
      action: 'Submit photo'
    });
  };
  function clearToDefault(){
    handleClearToDefault();
    ReactGA.event({
      category: 'Clear',
      action: 'Clear photo'
    });
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <a href="https://www.facebook.com/sherlomeapp" target="_blank">
            <Avatar className={classes.avatar}>
              <img src={logo} alt="Sherlome Logo" className={classes.avatar}/>
            </Avatar>
          </a>
          <Typography component="h1" variant="h4">
            Sherlome
          </Typography>
          <Typography component="h6" variant="h6">
            Relive your memories
          </Typography>
          <div className={classes.form}>
            {imgSrc !== null ? 
            <div className={classes.imgDiv}>
                <ReactCrop className={classes.cropZone} src={imgSrc} imageStyle={{"maxWidth":"100%", "minHeight":"inherit", "maxHeight":"50vh"}} onChange={handleOnCropChange} crop={crop} onImageLoaded={handleImageLoaded} onComplete={handleOnCropComplete}/> 
                <br />
                <canvas style={{display:"None"}} ref={imagePreviewCanevasRef}></canvas>
                {/*this.state.loading ? 
                ""
                :
                <button onClick={this.handleClearToDefault}> Clear</button>
                */}
                <Button onClick={clearToDefault} variant="outlined" color="secondary" > Clear</Button>
                <Button onClick={nextSlide} style={{marginLeft:"5px"}} variant="outlined" color="primary" > Next </Button>
            </div>
            :
            <Dropzone className={classes.dropZone} activeStyle={dropZoneActive} accept={acceptedFileTypes} onDrop={ handleDrop } disableClick>
                {({ open }) => (
                <React.Fragment>
                    <p>Drop photo here.</p>
                    <p><small>or</small></p>
                    <Button 
                        variant="outlined"
                        color="primary" 
                        onClick={() => {
                          open(); 
                          ReactGA.event({
                            category: 'PutPhoto',
                            action: 'Browse photo'
                          });}}>
                      Browse Photos
                    </Button>
                </React.Fragment>
                )}
            </Dropzone>
            }
          </div>
        </Paper>
      </main>
    </React.Fragment>
  );
}

ImageDropCrop.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImageDropCrop);