import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import zoomIn from './zoomin.svg';
import ReactGA from 'react-ga';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    padding: "15px",
    width: "auto",
    maxWidth: "100vw",
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
});

class ImgModal extends React.Component {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
    ReactGA.event({
      category: 'Zoom',
      action: 'Open modal'
    });
  };

  handleClose = () => {
    this.setState({ open: false });
    ReactGA.event({
      category: 'Zoom',
      action: 'Close modal'
    });
  };

  render() {
    const { classes, imgSrc } = this.props;

    return (
      <div>
        <Button onClick={this.handleOpen}><img alt="ZoomIn" src={zoomIn}/></Button>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <img alt="modal" style={{maxWidth:"90vw", maxHeight:"90vh"}} src={imgSrc}/>
          </div>
        </Modal>
      </div>
    );
  }
}

ImgModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

// We need an intermediary variable for handling the recursive nesting.
const ImgModalWrapped = withStyles(styles)(ImgModal); //eslint-disable-line no-unused-vars

export default ImgModalWrapped;